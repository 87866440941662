import styled from 'styled-components'

export const FormTitle = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.primary.main}; 
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;

`;
export const FormText = styled.div.attrs({
})`           
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: ${props => props.theme.palette.colors.grey};
`;

export const FormSpacer = styled.div.attrs({
})`           
    margin-top: 32px;
    ${p => p.border ? `
        margin-bottom: 32px;
        border: 0.5px solid ${p.theme.palette.colors.border};
        ` : ''}
`;

export const ForgotContent = styled.div.attrs({})`
    width: 100%;
    display: flex;
    height: fit-content;
    padding: 40px;
    flex-direction: column;
    background: ${p => p.theme.palette.colors.white};
    border-radius: 8px;
    @media (max-width: 768px) {
        padding: 20px;
    }

`;

