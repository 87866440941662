import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Landpage from 'screens/Landpage'
import NotFound from 'screens/NotFound'

import Login from 'screens/Authentication/Login'
import Register from 'screens/Authentication/Register'
import Forgot from 'screens/Authentication/Forgot'
import CreatePassword from 'screens/Authentication/CreatePassword'

import DashboardHome from 'screens/Dashboard/Home'
import DashboardMe from 'screens/Dashboard/Me'
import Password from "screens/Dashboard/Password";
import Employees from "screens/Employees";
import Advancements from "screens/Advancements";
import PaymentOrder from "screens/PaymentOrder";
import EmployeesForm from "screens/EmployeesForm";
import AdvancementsDetails from "screens/AdvancementsDetails";
import OrderDetails from "screens/OrderDetails";



export default function AppRouter() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/" exact>
            <Redirect to="login" />
          </Route>

          <Route path="/login" exact> <Login /> </Route>
          <Route path="/register" exact> <Register /> </Route>
          <Route path="/forgot" exact> <Forgot /> </Route>
          <Route path="/create-password" exact> <CreatePassword /> </Route>

          <Route path="/dashboard" exact> <DashboardHome /> </Route>

          <Route path="/profile" exact> <DashboardMe /> </Route>

          <Route path="/password" exact> <Password /> </Route>

          <Route path="/employees" exact> <Employees /> </Route>
          <Route path="/employees/form" exact> <EmployeesForm /> </Route>
          <Route path="/employees/:id" exact> <EmployeesForm /> </Route>

          <Route path="/advancements" exact> <Advancements /> </Route>
          <Route path="/advancements/:id" exact> <AdvancementsDetails /> </Route>

          <Route path="/payment-order" exact> <PaymentOrder /> </Route>
          <Route path="/payment-order/:id" exact> <OrderDetails /> </Route>

          <Route path="*" exact> <NotFound /> </Route>
        </Switch>
      </div>
    </Router>
  );
}