import React, { useState } from 'react'
import { ContentHeaderTitleContainer, ContentWrapperContainer, ContentWrapperButtonContainer, ContentWrapperHeader, Footer } from './styled'
import { ContainerOutlined, FormSpacer, Icon, Title } from 'ui/styled'
import Button from 'components/Form/Button'
import Input from 'components/Form/Input'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import AnticipationCard from 'components/AnticipationCard'

export default function WrapperContent({ children, title, back, buttons, footer, payment, loading }) {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const backAction = () => {
    history.goBack();
  }


  return (
    <>
      <ContentWrapperContainer>
        <ContentWrapperHeader>
          <ContentHeaderTitleContainer>
            {!back ? null : <Icon icon={'chevron-left'} nomargin pointer onClick={backAction} />}
            {!title ? null : <Title nomargin> {title} </Title>}
          </ContentHeaderTitleContainer>
          <ContentWrapperButtonContainer>
            {!payment ? null : <>
              <AnticipationCard
                title={payment?.title}
                value={payment?.value}
                color={payment?.color}
                info={payment?.info}
                small
              />
            </>}
            {
              buttons?.map((m, k) => (
                <Button width='fit-Content' loading={m?.loadable && loading} rigthIcon={m?.rigthIcon} leftIcon={m?.leftIcon} outlineGradient={m?.outlineGradient} gradient={m?.gradient} nospace onClick={m?.action} small>
                  {m?.label}
                </Button>
              ))
            }
          </ContentWrapperButtonContainer>
        </ContentWrapperHeader>
        {children}
        {!footer ? null : <Footer >
          {
            buttons?.map((m, k) => (
              <Button width='fit-Content' loading={m?.loadable && loading} rigthIcon={m?.rigthIcon} leftIcon={m?.leftIcon} outlineGradient={m?.outlineGradient} gradient={m?.gradient} nospace onClick={m?.action} small>
                {m?.label}
              </Button>
            ))
          }
        </Footer>}
      </ContentWrapperContainer>
    </>
  )
}
