import styled from 'styled-components'

export const Content = styled.div.attrs({
})`
`;

export const FormWrapper = styled.div.attrs({
})`
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    @media(max-width:767px){
        flex-direction: column;
    }
`;

export const FormInput = styled.div.attrs({
})`
    width: 100%;
    max-width: calc(calc(100% - 28px) / 3);
    ${props => props.full ? `
            max-width: 100%;
        ` : ``
    }

    ${props => props.half ? `
            max-width: calc(calc(100% - 14px) / 2);
        ` : ``
    }

    ${props => props.quarter ? `
            max-width: calc(calc(100% - 21px) / 4);
        ` : ``
    }
    
    ${props => props.twenty ? `
            max-width: calc(calc(100% - 32px) / 5);
        ` : ``
    }
    ${props => props.six ? ` 
            max-width: calc(calc(100% - 58px) / 6);
        ` : ``}

    @media(max-width: 1300px){
        max-width: calc(calc(100% - 28px) / 3);
    }
    
    @media(max-width: 1024px){
        max-width: calc(calc(100% - 14px) / 2);
    }

    @media(max-width:767px){
        max-width: 100%;
    }
`;

export const FormSeparator = styled.div.attrs({
})`
    margin: 12px 0;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.palette.colors.shadow};
`;

export const ContentForm = styled.div.attrs({
})`
`;

