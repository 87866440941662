import React, { useContext, useEffect, useMemo, useRef, useState } from "react";


import ContainerAuthenticated from "containers/Authenticated";
import { useHistory } from "react-router-dom";
import WrapperContent from "components/WrapperContent";
import FormCore from "../../../components/Form/Core"
import FormWrapper from "components/FormWrapper";
import { CoreContext } from "context/CoreContext";
import { ReadMe, UpdateMe } from "services/me";
import { toast } from "react-toastify";
import { exposeStrapiError } from "utils";

export default function DashboardMe() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { user, setUser } = useContext(CoreContext)

    const [loading, setLoading] = useState(false);

    const register = useMemo(() => {
        return { ...user }
    }, [user])

    const formItems = useMemo(() => {
        return [
            {
                ref: 'full_name',
                type: 'text',
                placeholder: 'Nome completo',
            },
            {
                ref: 'rg',
                type: 'text',
                placeholder: 'RG',
            },
            {
                ref: 'cpf',
                type: 'text',
                placeholder: 'CPF',
                mask: '999.999.999-99'
            },
            {
                ref: 'birthdate',
                type: 'date',
                placeholder: 'Data de nascimento',
            },
            {
                ref: 'email',
                type: 'text',
                placeholder: 'E-mail',
            },
            {
                ref: 'phone',
                type: 'text',
                placeholder: 'DDD + Telefone',
                mask: '(99) 99999-9999'
            },
        ];
    }, [])

    const formRef = useRef()

    const buttons = [
        {
            action: () => history.goBack(),
            label: 'Cancelar',
            outlineGradient: true,
        },
        {
            action: () => save(),
            label: 'Editar',
            gradient: true,
            loadable: true
        },
    ];

    const valid = (payload, array) => {
        for (let item of array) {
            if (item?.ref && !payload?.[item?.ref]) {
                return false;
            }
        }
        return true;
    };
    
    const save = async () => {
        const form = formRef?.current?.getForm()    

        if(!valid(form, formItems)){
            toast.error("Preencha os dados do funcionário")
            return;
        }

        const payload = {
            ...form
        }
        
        setLoading(true)
        const result = await UpdateMe({ ...payload })
        await reloadMe()
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            toast.success("Salvo com sucesso")
            history.goBack()
        }
    }

    const reloadMe = async () => {
        const result = await ReadMe()
        if(result?.id){
            setUser(result)
        } 
    }

    return (
        <>
            <ContainerAuthenticated>
                <WrapperContent title={'Minha conta'} buttons={buttons} loading={loading} back footer>
                    <FormWrapper title={"Dados do  funcionário"}>
                        <FormCore ref={formRef} formItems={formItems} register={register} />
                    </FormWrapper>
                </WrapperContent>
            </ContainerAuthenticated>
        </>
    );
}