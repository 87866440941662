import styled from "styled-components";

export const ContentWrapperContainer = styled.div.attrs({
})`
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.palette.colors.white};
  padding: 16px;
  border-radius: 4px;
`;

export const ContentWrapperHeader = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: ${p => p.space ? '16px' : '0'};
  
`;

export const ContentWrapperButtonContainer = styled.div.attrs({
})`
  display: flex;
   gap: 16px;
`;

export const ContentHeaderTitleContainer = styled.div.attrs({})`
  display: flex;
  flex-direction: column;
  min-width: 307px;
  @media (max-width: 768px) {
  min-width: 100%;
  }
`;