import React from "react";
import PropTypes from 'prop-types';

import { BorderGradient, ColorButton, Load } from "./styled";
import { ThemedComponent } from "ui/theme";
import { Icon } from "ui/styled";

export const Button = ({ children, loading, primary, secondary, outline, link, nospace, centred, onClick, gradient, width, outlineGradient, leftIcon, rigthIcon, between, small }) => {
  const Button = outlineGradient ? BorderGradient : ColorButton;


  return (
    <>
      <ThemedComponent>
        <Button gradient={gradient} variant={link ? "text" : outline ? "outlined" : "contained"} color={secondary ? 'secondary' : primary ? 'primary' : 'white'} width={width} nospace={nospace} centred={centred} onClick={onClick} between={between} small={small}>
          {
            loading ? <Load primary={primary} secondary={secondary} outline={outline} /> : <>
              {!leftIcon ? null : <Icon icon={leftIcon} pointer />}
              {children}
              {!rigthIcon ? null : <Icon icon={rigthIcon} pointer />}
            </>
          }
        </Button>
      </ThemedComponent>
    </>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  outline: PropTypes.bool,
  link: PropTypes.bool,
  nospace: PropTypes.bool,
  centred: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  children: undefined,
  primary: false,
  secondary: false,
  outline: false,
  link: false,
  nospace: false,
  centred: false,
  loading: false,
  onClick: undefined,
};

export default Button;