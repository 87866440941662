import React, { useContext, useEffect, useMemo, useRef, useState } from "react";


import ContainerAuthenticated from "containers/Authenticated";
import { useHistory } from "react-router-dom";
import WrapperContent from "components/WrapperContent";
import FormWrapper from "components/FormWrapper";
import { toast } from "react-toastify";
import Button from "components/Form/Button";
import Input from 'components/Form/Input'
import { DoResetPassword } from "services/authentication";
import { exposeStrapiError } from "utils";
import PasswordValidation from "components/Form/PasswordValidation";
import { InputContent, PasswordContainer, PasswordValidationContent } from "./styled";
import { UpdateMePassword } from "services/me";
export default function Password() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const params = new URLSearchParams(window.location.search)
    const [loading, setLoading] = useState(false) 

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const valid = (verbose = false) => {

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error('Preencha o campo: Nova senha'); }
            return false;
        }

        if (!formValue('cpassword') || !formValue('cpassword').length) {
            if (verbose) { toast.error('Preencha o campo: Confirmar nova senha'); }
            return false;
        }

        if (formValue('password') !== formValue('cpassword')) {
            if (verbose) { toast.error('Nova senha e confirmação da nova senha não são iguais'); }
            return false;
        }

        return true
    } 

    const save = async () => {

        if(!valid(true)){  return; }

        const payload = {
            ...form
        }
        
        setLoading(true)
        const result = await UpdateMePassword({ ...payload })
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            toast.success("Senha atualizada com sucesso")
            history.goBack()
        }
    }

    const buttons = [
        {
            action: () => history.goBack(),
            label: 'Cancelar',
            outlineGradient: true,
        },
        {
            action: () => save(),
            label: 'Editar',
            gradient: true,
            loadable: true
        },
    ];

    return (
        <>
            <ContainerAuthenticated>
                <WrapperContent title={'Senha'} buttons={buttons} loading={loading} back footer>
                    <FormWrapper title={"Alterar senha"}>
                        <PasswordContainer>
                            <InputContent>
                                <Input id={'password'} placeholder="Nova Senha" type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} />
                                <Input id={'cpassword'} placeholder="Confirmar nova Senha" type="password" value={formValue('cpassword')} onChange={e => changeForm(e.target.value, 'cpassword')} />
                            </InputContent>
                            <PasswordValidationContent>
                                <PasswordValidation password={formValue('password')} noMargin />
                            </PasswordValidationContent>
                        </PasswordContainer>
                    </FormWrapper>
                </WrapperContent>
            </ContainerAuthenticated>
        </>
    );
}