import React, { useContext, useEffect, useState } from "react";

import DashboardSide from "../Side";
import {
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon
} from "./styled";
import { CoreContext } from "context/CoreContext";
import useWindowSize from "utils/useWindowSize";
import { Icon, Title } from "ui/styled";
import HeaderUserOptions from "../HeaderUserOptions";
import { ReadMe } from "services/me";


export default function DashboardHeader() {

    const { opened, setOpened, user, setUser } = useContext(CoreContext)
    const { width } = useWindowSize()

    const [active, setActive] = useState(false)

    const init = async () => {
        const result = await ReadMe()
        if(result?.id){
            setUser(result)
        } 
    }

    useEffect(() => { init() ; }, [])

    return (
        <>
            <DashboardHeaderContainer>
                <DashboardHeaderAction >
                    <DashboardHeaderActionIcon src={`/icons/menu.svg`} alt="menu-icon" onClick={() => setOpened(true)} />
                </DashboardHeaderAction>
                <DashboardHeaderAction >
                    <Title small nomargin none>
                        { user?.company?.name }
                    </Title>
                    <Icon icon='user' pointer onClick={() => setActive(!active)} />
                </DashboardHeaderAction>
                {/*  */}
                {!active ? null : <HeaderUserOptions />}
            </DashboardHeaderContainer>
            <DashboardSide />
        </>
    );
}