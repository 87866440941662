import styled from "styled-components";

export const Content = styled.div.attrs({
})`
`;
export const PaginationContainer = styled.div.attrs({
})`
  display: flex;
  align-items: center;
  margin-top: 8px;
  flex-wrap: wrap; 
  min-width: 100%;
`;

export const PaginationQuantity = styled.div.attrs({})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left; 
  width: 100%;
  margin: 12px 0 0;
`;