import TableWrapper from 'components/TableWrapper'
import React, { useMemo } from 'react'
import { TableContainer } from './styled'
import BasicTable from 'components/Form/Table'
import { Icon, IconContainer } from 'ui/styled'

import { useHistory } from 'react-router-dom';

export default function HomeTable({ infos }) {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);


  const latestEmployees = [
    {
      action: () => navigate("employees"),
      label: 'Ver todos',
      outlineGradient: true,
    }
  ]

  const latestAnticipation = [
    {
      action: () => navigate("advancements"),
      label: 'Ver todos',
      outlineGradient: true,
    }
  ]

  const latestAnticipationRows = useMemo(() => infos?.last_antecipation || [] , [])
  
  const rows = useMemo(() => infos?.last_register_employees || [] , [])
  // [
  //   {
  //     id: 1, name: 'João da silva', cpf: '123.456.789-12',
  //   },
  //   {
  //     id: 2, name: 'João da silva', cpf: '123.456.789-12',
  //   },
  //   {
  //     id: 3, name: 'João da silva', cpf: '123.456.789-12',
  //   },
  // ];

  const columns = [
    { title: 'Funcionário', ref: 'full_name', icon: true, noWrap: true, width: '100%' },
    { title: 'CPF', ref: 'cpf', icon: true, noWrap: true, width: '50%' },
    {
      title: '', ref: 'actions', renderCell: ({ row }) =>
        <IconContainer>
          <Icon icon='chevron-right' pointer nomargin onClick={() =>  navigate(`employees/${ row?.id }`)} />
        </IconContainer>
      , width: '40px', flexGrow: 0
    },
  ];

  const latestAnticipationColumns = [
    { title: 'Funcionário', ref: 'name', icon: true, noWrap: true, width: '100%' },
    { title: 'CPF', ref: 'cpf', icon: true, noWrap: true, width: '50%' },
    {
      title: '', ref: 'actions', renderCell: ({ row }) =>
        <IconContainer>
          <Icon icon='chevron-right' pointer nomargin onClick={() =>  navigate(`employees/${ row?.id }`)} />
        </IconContainer>
      , width: '40px'
    },
  ];

  const employeesUsedRows = useMemo(() => (infos?.most_used_service || []).map(m => ({ ...m, name: m?.full_name, anticipation: m?.count })) , [])
  

  const employeesUsedColumns = [
    { title: 'Funcionário', ref: 'name', icon: true, noWrap: true, width: '100%' },
    { title: 'antecipações', ref: 'anticipation', icon: true, noWrap: true, width: '50%' },
  ];


  const employeesAnticipationRows = useMemo(() => (infos?.top_rated_value || []).map(m => ({ ...m, name: m?.full_name, value: m?.value })) , [])
  

  const employeesAnticipationColumns = [
    { title: 'Funcionário', ref: 'name', icon: true, noWrap: true, width: '100%' },
    { title: 'Valor', ref: 'value', icon: true, noWrap: true, width: '50%' },
  ];

  return (
    <>
      <TableContainer>
        <TableWrapper title={"Últimos funcionários cadastrados"} buttons={latestEmployees} >
          <BasicTable columns={columns} rows={rows} />
        </TableWrapper>
        <TableWrapper title={"Últimas antecipações"} buttons={latestAnticipation} >
          <BasicTable columns={latestAnticipationColumns} rows={latestAnticipationRows} />
        </TableWrapper>
      </TableContainer>
      <TableContainer>
        <TableWrapper title={"Funcionários que mais utilizaram os serviços"} >
          <BasicTable columns={employeesUsedColumns} rows={employeesUsedRows} />
        </TableWrapper>
        <TableWrapper title={"Funcionários que anteciparam maior volume (R$)"}>
          <BasicTable columns={employeesAnticipationColumns} rows={employeesAnticipationRows} />
        </TableWrapper>
      </TableContainer>
    </>
  )
}
