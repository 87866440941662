import React, { useContext, useEffect, useState } from "react";

import { useHistory } from 'react-router-dom';

import {

    DashboardMenuContainer,
    DashboardMenu,
    DashboardMenuHeader,
    DashboardMenuHeaderIcon,

    DashboardMenuHeaderUserContent,
    DashboardMenuHeaderUserImage,

    DashboardMenuOption,
    DashboardMenuContent,
    DashboardMenuFooter,

    DashboardVersionContent,
    DashboardVersionText

} from "./styled";

import Button from "components/Form/Button";
import { DoLogout } from "services/authentication";
import { Icon } from "ui/styled";
import { CoreContext } from "context/CoreContext";
import useWindowSize from "utils/useWindowSize";

export default function DashboardSide() {
    const { width } = useWindowSize()

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const pathname = history.location.pathname;

    const { opened, setOpened } = useContext(CoreContext)


    const [active, setActive] = useState(0);

    const page = pathname.split('/')[1]

    const verifyClose = e => {
        if (!e.target.closest('.menu-contant')) {
            setOpened(false)
        }
    }

    useEffect(() => {
        if (width <= 991) {
            setOpened(false)
        } else {
            setOpened(true)
        }
    }, [width])

    const exit = async () => {
        await DoLogout()
        navigate('login')
    }

    const menuItems = [
        {
            name: "Home",
            page: 'dashboard'
        },
        {
            name: "Funcionários",
            page: 'employees'
        },
        {
            name: "Antecipações",
            page: 'advancements'
        },
        {
            name: "Ordem de pagamento",
            page: 'payment-order'
        },
    ];

    const handleClick = (page) => {
        if (page) {
            navigate(page)
        }
        return;
    }


    return (
        <>
            {
                !opened ? null :
                    <DashboardMenuContainer >
                        <DashboardMenu>
                            <DashboardMenuHeader >
                                <Icon icon={'logo-blue'} />
                                <DashboardMenuHeaderIcon src={'/icons/close-black.svg'} onClick={() => setOpened(false)} />
                            </DashboardMenuHeader>
                            <DashboardMenuContent>
                                {
                                    menuItems.map((item, index) => (
                                        <DashboardMenuOption key={index}
                                            onClick={() => handleClick(item?.page)}
                                            active={page === item.page}
                                        >
                                            {item.name}
                                        </DashboardMenuOption>
                                    ))
                                }
                            </DashboardMenuContent>

                            {/* <DashboardMenuFooter>
                                <DashboardMenuOption primary outline onClick={exit} centred>
                                    Sair
                                </DashboardMenuOption>
                            </DashboardMenuFooter> */}

                        </DashboardMenu>
                    </DashboardMenuContainer>
            }
        </>
    );
}