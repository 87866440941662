import React, { useContext, useEffect, useMemo, useState } from "react";

import ContainerAuthenticated from "containers/Authenticated";
import { CoreContext } from "context/CoreContext";
import useWindowSize from "utils/useWindowSize";
import WrapperContent from "components/WrapperContent";
import HomeTable from "components/HomeTable";
import TableWrapper from "components/TableWrapper";
import BasicTable from "components/Form/Table";
import { Icon, IconContainer } from "ui/styled";
import { StatusBadge } from "./styled";
import FilterForms from "components/FilterForms";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReadHomePaymentOrders } from "services/bff";
import { numerize } from "utils";
import { ENUM_MONTHS, ENUM_STATUS } from "utils/options";
import { parseCurrency } from "utils/parsers";
import moment from "moment/moment";

export default function PaymentOrder() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { setModal, setOpened } = useContext(CoreContext)
    const { width } = useWindowSize()

    const [registers, setRegisters] = useState(null);
    const [filters, setFilters] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [searchExpression, setSearchExpression] = useState("");


    const handleOpenModal = () => {
        setModal({
            type: 'sample',
            // ...anything you need
        })
    }

    const rows = useMemo(() => {
        return (registers || []);
    }, [registers])

    const paymentDetails = useMemo(() => ({
        title:'Valor a receber',
        value: parseCurrency(rows?.reduce((p, c) => p + (c?.order_value||0), 0) || 0) ,
        color:'primary',
        info:`${ numerize(rows?.length) } Antecipações em ${ ENUM_MONTHS[rows?.[0]?.month_ref] }`,
   }), [rows])

    const columns = [
        { title: 'NÚMERO DA ORDEM', ref: 'order_number', icon: true, noWrap: true, width: '15%' },
        { title: 'MÊS REF.', ref: 'monthRef', icon: true, noWrap: true, width: '15%' },
        { title: 'QTD. ANTECIPAÇÕES', ref: 'antecipations', icon: true, noWrap: true, width: '15%' },
        { title: 'VENCIMENTO BOLETO', ref: 'dueDate', icon: true, noWrap: true, width: '15%' },
        { title: 'VALOR DA ORDEM', ref: 'orderValue', icon: true, noWrap: true, width: '15%' },
        {
            title: 'STATUS', ref: 'status', icon: true, noWrap: true, width: '15%', renderCell: ({ row }) =>
                <StatusBadge background={row.status}>{row.status}</StatusBadge>
        },
        {
            title: '', ref: 'actions', renderCell: ({ row }) =>
                <IconContainer>
                    <Icon icon='chevron-right' pointer nomargin onClick={() => navigate(`payment-order/${row?.id}`)} />
                </IconContainer>
            , width: '40px', flexGrow: 0
        },
    ];

    const formItems = useMemo(() => {
        const stOptions = (rows || [])?.map(m => ({ id: m?.status, title: m?.status }))?.reduce((p, c) => p?.map(m => m?.id)?.includes(c?.id) ? p : [...p, c], [])
        const monthOptions = (rows || [])?.map(m => ({ id: m?.monthRef, title: m?.monthRef }))?.reduce((p, c) => p?.map(m => m?.id)?.includes(c?.id) ? p : [...p, c], [])
        return [
            {
                ref: 'month', 
                options: monthOptions,
                placeholder: 'Mês ref',
                full: true,
            },
            {
                ref: 'raw_dueDate',
                type: 'date',
                placeholder: 'Vencimento boleto',
                full: true,
            },
            {
                ref: 'status',
                options: stOptions,
                placeholder: 'Status',
                full: true,
                filled: true,
            },
        ];
    }, [rows])

    const tableButtons = [
        {
            action: () => setIsActive(true),
            label: 'Filtros',
            leftIcon: 'filter',
            outlineGradient: true,
        }
    ]

    const filterFilters = item => {
        return !filters || (
            Object?.keys(filters)?.some(ref =>
                item?.[ref]?.toLowerCase()?.indexOf(filters?.[ref]?.toLowerCase()) !== -1
            )
        )
    }

    const init = async () => {
        const result = await ReadHomePaymentOrders() 
        
        if (result?.length) {
            setRegisters(result.map(m => ({ 
                ...m, 
                status: ENUM_STATUS[m?.status] || "" ,
                raw_dueDate: moment(m?.due_date).format("YYYY-MM-DD"),
                dueDate: moment(m?.due_date).format("DD/MM/YYYY"),
                orderValue: parseCurrency(m?.order_value),
                monthRef: ENUM_MONTHS[m?.month_ref]
            })))
        }

    };


    const filterExpression = item => {
        return (!searchExpression || Object.keys(item).filter(k => `${item[k]}`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1).length > 0)
    }

    useEffect(() => { init(); }, []);

    return (
        <>
            <ContainerAuthenticated>
                <WrapperContent title={'Ordem de pagamento'} back payment={paymentDetails}>
                    <TableWrapper
                        buttons={tableButtons}
                        setSearchExpression={setSearchExpression}
                        totalResults={rows?.filter(filterFilters)?.filter(filterExpression)?.length}
                        search >
                        <BasicTable columns={columns} rows={rows?.filter(filterFilters)?.filter(filterExpression)} tableMin={800} />
                    </TableWrapper>
                </WrapperContent>
                <FilterForms formItems={formItems} setFilters={setFilters} isActive={isActive} setIsActive={setIsActive} />
            </ContainerAuthenticated>
        </>
    );
}