import styled from "styled-components";

export const TitleContainer = styled.div.attrs({
})`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
`;

export const FormSpace = styled.div.attrs({
})`
  margin-top: 32px;
`;

export const WhiteBg = styled.div.attrs({
})`
`;

export const CheckContainer = styled.div.attrs({
})`
    margin-left: -6px;    
`;