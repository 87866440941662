import styled from "styled-components";

export const InputContent = styled.div.attrs({})`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const PasswordValidationContent = styled.div.attrs({})`
    width: 100%;

`;

export const PasswordContainer = styled.div.attrs({})`
    display: flex;
    gap: 40px;
    margin-top: 16px;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
    }
`;

