import { GET, POST, PUT, DELETE } from "./api";

export const ReadHomeAdmin = async (filters) => {
    // ?startDate=2024-09-02&endDate=2024-09-05 
    const qs = `startDate=${filters?.startDate||""}&endDate=${filters?.endDate||""}`
    return await GET(`/home-admin?${qs}`, true);
}

export const ReadHomeAntecipation = async (filters) => {
    // ?startDate=2024-09-02&endDate=2024-09-05&status=paid
    const qs = `startDate=${filters?.startDate||""}&endDate=${filters?.endDate||""}&status=${filters?.status||""}`
    return await GET(`/antecipation-admin?${qs}`, true);
}

export const ReadHomeEmployee = async (filters) => {
    // ?position=manager&salary=2200
    const qs = `position=${filters?.position||""}&salary=${filters?.salary||""}`
    return await GET(`/employees-admin?${qs}`, true);
}

export const ReadHomePaymentOrders = async (filters) => {
    // ?startDate=2024-09-02&endDate=2024-09-05&status=paid
    const qs = `startDate=${filters?.startDate||""}&endDate=${filters?.endDate||""}&status=${filters?.status||""}`
    return await GET(`/order-admin?${qs}`, true);
}
