import React, { useContext, useEffect, useMemo, useState } from "react";

import ContainerAuthenticated from "containers/Authenticated";
import { CoreContext } from "context/CoreContext";
import useWindowSize from "utils/useWindowSize";
import WrapperContent from "components/WrapperContent";
import HomeTable from "components/HomeTable";
import TableWrapper from "components/TableWrapper";
import BasicTable from "components/Form/Table";
import { Icon, IconContainer } from "ui/styled";
import { StatusBadge } from "./styled";
import FilterForms from "components/FilterForms";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReadHomeAntecipation } from "services/bff";
import moment from "moment/moment";
import { ENUM_STATUS } from "utils/options";
import { parseCurrency } from "utils/parsers";

export default function Advancements() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { setModal, setOpened } = useContext(CoreContext)
    const { width } = useWindowSize()

    const [registers, setRegisters] = useState(null);
    const [filters, setFilters] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [searchExpression, setSearchExpression] = useState("");

    const rows = useMemo(() => {
        return (registers || []);
    }, [registers])

    const columns = [
        { title: 'FUNCIONÁRIO', ref: 'full_name', icon: true, noWrap: true, width: '20%' },
        { title: 'CPF', ref: 'cpf', icon: true, noWrap: true, width: '15%' },
        { title: 'DATA SOLICIT.', ref: 'requestDate', icon: true, noWrap: true, width: '15%' },
        { title: 'DATA ANTEC.', ref: 'advanceDate', icon: true, noWrap: true, width: '15%' },
        { title: 'VALOR', ref: 'value', icon: true, noWrap: true, width: '10%' },
        {
            title: 'STATUS', ref: 'status', icon: true, noWrap: true, width: '15%', renderCell: ({ row }) =>
                <StatusBadge background={row.status}>{row.status}</StatusBadge>
        },
        {
            title: '', ref: 'actions', renderCell: ({ row }) =>
                <IconContainer>
                    <Icon icon='chevron-right' pointer nomargin onClick={() => navigate(`advancements/${row?.id}`)} />
                </IconContainer>
            , width: '40px', flexGrow: 0
        },
    ];

    const tableButtons = [
        {
            action: () => setIsActive(true),
            label: 'Filtros',
            leftIcon: 'filter',
            outlineGradient: true,
        }
    ]

    const formItems = useMemo(() => {
        const stOptions = (rows || [])?.map(m => ({ id: m?.status, title: m?.status }))?.reduce((p, c) => p?.map(m => m?.id)?.includes(c?.id) ? p : [...p, c], [])
        return [
            {
                ref: 'raw_requestDate',
                type: 'date',
                placeholder: 'Data de solicitação',
                full: true,
            },
            {
                ref: 'raw_advanceDate',
                type: 'date',
                placeholder: 'Data da antecipação',
                full: true,
            },
            {
                ref: 'status',
                options: stOptions,
                placeholder: 'Status',
                full: true,
                filled: true,
            },
        ];
    }, [rows])

    const filterFilters = item => {
        return !filters || (
            Object?.keys(filters)?.some(ref =>
                item?.[ref]?.toLowerCase()?.indexOf(filters?.[ref]?.toLowerCase()) !== -1
            )
        )
    }

    const init = async () => {
        const result = await ReadHomeAntecipation()
        if (result?.length) {
            setRegisters(result.map(m => ({ 
                ...m, 
                raw_requestDate: moment(m?.createdAt).format("YYYY-MM-DD"),
                raw_advanceDate: moment(m?.updatedAt).format("YYYY-MM-DD"),
                requestDate: moment(m?.createdAt).format("DD/MM/YYYY"),
                advanceDate: moment(m?.updatedAt).format("DD/MM/YYYY"),
                status: ENUM_STATUS[m?.status] || "" ,
                value: parseCurrency(m?.value)
                // 
            })))
        }
    };

    useEffect(() => { init(); }, []);

    const filterExpression = item => {
        return (!searchExpression || Object.keys(item).filter(k => `${item[k]}`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1).length > 0)
    }

    return (
        <>
            <ContainerAuthenticated>
                <WrapperContent title={'Antecipações'} back>
                    <TableWrapper
                        buttons={tableButtons}
                        setSearchExpression={setSearchExpression}
                        totalResults={rows?.filter(filterFilters)?.filter(filterExpression)?.length}
                        search
                    >
                        <BasicTable columns={columns} rows={rows?.filter(filterFilters)?.filter(filterExpression)} pagination tableMin={800} />
                    </TableWrapper>
                </WrapperContent>
                <FilterForms formItems={formItems} setFilters={setFilters} isActive={isActive} setIsActive={setIsActive} />

            </ContainerAuthenticated>
        </>
    );
}