import styled from "styled-components";

export const FormWrapperContainer = styled.div.attrs({})`
  padding: ${p => p.noSpace ? '16px 0 0 0' : '16px 16px 32px 16px'};
  background: ${p => p.theme.palette.colors.white};
  border-radius: 4px;
`;

export const FormSpacer = styled.div.attrs({
})`
    margin-top: 16px;
    border-top: 0.5px solid ${p => p.theme.palette.colors.border};
    margin-bottom: 16px;
`;