import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

import AnticipationCard from "components/AnticipationCard";
import { ContentForm, FormInput, FormWrapper } from "./styled";


export default function AnticipationInfo({ Items, small }) {


    return (
        <>
            <ContentForm>
                <FormWrapper>
                    {
                        Items.map((item, key) => <>
                            {
                                <FormInput full={item.full} half={item.half} quarter={item.quarter} twenty={item.twenty} six={item.six} key={key}>
                                    <AnticipationCard title={item?.title} value={item?.value} color={item?.color} small={small} />
                                </FormInput>
                            }
                        </>
                        )
                    }
                </FormWrapper>
            </ContentForm>
        </>
    );
}