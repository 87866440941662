import React, { useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer,
    ForgotContent
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoForgotPassword } from "services/authentication";
import { exposeStrapiError } from "utils";
import { ButtonContainer } from "ui/styled";

export default function Forgot() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(0)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const valid = (verbose = false) => {

        if (!formValue('email') || !formValue('email').length) {
            if (verbose) { toast.error('Preencha o campo: Email'); }
            return false;
        }

        return true
    }

    const action = async () => {
        if (!valid(true)) { return; }
        setLoading(true)

        const result = await DoForgotPassword({ email: formValue('email')?.replace(/ /g, '') })

        setLoading(false)
        if (result && !exposeStrapiError(result)) {
            completNext()
        }
    }

    const completNext = () => {
        toast.success('Instruções para recuperar senha foram enviadas ao seu email');
        setStep(step + 1);
    }

    const steps = useMemo(() => [
        {
            title: 'Esqueceu sua senha?',
            text: 'Digite seu e-mail cadastrado para recuperar sua senha de acesso',
            label: 'Continuar',
            label2: 'Voltar'
        },
        {
            title: 'Verifique seu email',
            text: 'Acesse o link de recuperação que foi enviado no seu email cadastrado',
            label: 'Fechar',
        },

    ], []);

    const next = () => {
        if (step === steps.length - 2) {
            action()
            return;
        }
        if (step === steps.length - 1) {
            navigate('Login')
            return;
        }
        setStep(step + 1);
    }
    const back = () => {
        if (step === 0) {
            navigate('Login')
            return;
        }
        setStep(step - 1);
    }


    return (
        <>
            <ContainerUnauthenticated>
                <ForgotContent>
                    <FormTitle>{steps?.[step]?.title}</FormTitle>
                    <FormText>{steps?.[step]?.text}</FormText>
                    {!(step === 0) ? null : <>
                        <FormSpacer />
                        <Input placeholder="Email" id={'email'} value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} />
                        <FormSpacer border />
                        <ButtonContainer>
                            <Button width={"fit-content"} nospace outlineGradient leftIcon={"chevron-orange"} onClick={() => history.goBack()}>{steps?.[step]?.label2}</Button>
                            <Button width={"172px"} outline gradient nospace rigthIcon={"chevron-white"} loading={loading} onClick={next} between>{steps?.[step]?.label}</Button>
                        </ButtonContainer>
                    </>}
                    {!(step === 1) ? null : <>
                        <FormSpacer border />
                        <ButtonContainer center>
                            <Button width={"298px"} outline gradient nospace loading={loading} onClick={next} >{steps?.[step]?.label}</Button>
                        </ButtonContainer>

                    </>
                    }
                </ForgotContent>
            </ContainerUnauthenticated>
        </>
    );
}