import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';

import { Row, Col } from 'reactstrap';
import { ReadObject } from "services/storage";
import { ThemedComponent } from "ui/theme";

import {
    SideBackgroundImageContainer,
    SideBackgroundImage,
    SideBackgroundImageDegree,

    FormContent,
    AppLogo,
    Content,
    Touch
} from './styled'


export default function ContainerUnauthenticated({ children, full }) {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const init = () => {
        const authentication = ReadObject('authentication')
        if (authentication?.jwt) {
            completeNext()
        }
    }

    const completeNext = () => {
        navigate('dashboard')
    }

    useEffect(() => {
        init()
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ThemedComponent>
                <Content>
                    <Row>
                        <Col md={{ size: full ? 12 : 7 }} className="nopadding">
                            <FormContent primary={full}>
                                {children}
                            </FormContent>
                        </Col>
                        {
                            full ? null : <Col md={{ size: 5 }} className="nopadding">
                                <SideBackgroundImageContainer>
                                    <SideBackgroundImage
                                        src={'/images/antecipei.png'} />
                                </SideBackgroundImageContainer>
                            </Col>
                        }
                    </Row>
                </Content>
            </ThemedComponent>
        </>
    );
}