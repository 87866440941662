import styled from 'styled-components'

export const DashboardPage = styled.div.attrs({
})`            
`;

export const DashboardBody = styled.div.attrs({
})`       
    min-height: calc(100vh - 104px);
    max-height: calc(100vh - 104px);
    background: ${props => props.theme.palette.colors.backgroundgrey};

    display: flex;
    align-items: flex-start;     
    justify-content: flex-end;
    overflow:auto;
`;

export const DashboardBodyContent = styled.div.attrs({
})`            
    background: ${props => props.theme.palette.colors.backgroundgrey};
    width: calc(100% - 252px);
    padding: 24px 32px;
    min-height: calc(100vh - 108px);
    @media (max-width: 991px ) {
        width: 100%;
        padding: 16px 16px 32px 16px;
    }
`;

export const Content = styled.div.attrs({
})`           
    overflow:hidden;
`;
export const HeaderContent = styled.div.attrs({
})`           
    width: 100%;
    display: flex;
    justify-content: flex-end;
`; 