import React from 'react'
import { AnticipationContent, AnticipationInfo, AnticipationTitle, AnticipationValue, FormBorder, TitleContent } from './styled'

export default function AnticipationCard({ title, value, color, info, small }) {
  return (
    <>
      <AnticipationContent row={info}>
        {!info ? null : <>
          <AnticipationInfo>
            {info}
          </AnticipationInfo>
          <FormBorder />
        </>
        }
        <TitleContent>
          <AnticipationTitle>
            {title}
          </AnticipationTitle>
          <AnticipationValue color={color} small={small}>
            {value}
          </AnticipationValue>
        </TitleContent>
      </AnticipationContent>
    </>
  )
}
