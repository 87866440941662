import React, { useContext, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer,
    RegisterForgot,
    ForgotLink,
    RegisterCall,
    LoginContainer,
    LoginContent
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { CoreContext } from "context/CoreContext";
import { DoLogin } from "services/authentication";
import { exposeStrapiError } from "utils";
import { Icon } from "ui/styled";

export default function Login() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [loading, setLoading] = useState(false)
    const { setUser } = useContext(CoreContext)

    const [form, setForm] = useState({
        // "identifier": "company@uorak.com",
        // "password": "Teste123"
    })
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const valid = (verbose = false) => {

        if (!formValue('identifier') || !formValue('identifier').length) {
            if (verbose) { toast.error('Preencha o campo: Email'); }
            return false;
        }

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error('Preencha o campo: Senha'); }
            return false;
        }

        return true
    }

    const login = async () => {
        if (!valid(true)) { return; }
        setLoading(true)
        const result = await DoLogin({ ...form, identifier: form.identifier?.replace(/ /g, '') })
        setLoading(false)
        if (result && !exposeStrapiError(result)) {
            completeLogin(result)
        }

    }

    const completeLogin = (result) => {
        if (result?.user) { setUser(result.user) }
        navigate('dashboard')
    }

    return (
        <>
            <ContainerUnauthenticated full>
                <LoginContainer>
                    <Icon icon="logo" />
                    <LoginContent>
                        <FormTitle>Olá! Entrar</FormTitle>
                        <Input placeholder="E-mail" value={formValue('identifier')} onChange={e => changeForm(e.target.value, 'identifier')} />
                        <Input placeholder="Senha" type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} onSubmitEditing={login} />
                        <ForgotLink onClick={() => navigate('forgot')}>Esqueci a senha</ForgotLink>
                        <Button gradient nospace loading={loading} onClick={login}>Entrar</Button>
                    </LoginContent>
                </LoginContainer>
            </ContainerUnauthenticated>
        </>
    );
}