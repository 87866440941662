import styled from "styled-components";

export const ContentWrapperContainer = styled.div.attrs({
})`
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;

`;

export const ContentWrapperHeader = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const ContentWrapperButtonContainer = styled.div.attrs({
})`
  display: flex;
   gap: 16px;
`;

export const ContentHeaderTitleContainer = styled.div.attrs({})`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Footer = styled.div.attrs({})`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
`;