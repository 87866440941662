import styled from 'styled-components'

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({
})`            
    font-size: 22px;
    font-weight: bold;
    color: ${props => props.theme.palette.colors.black};
    margin-bottom: 12px;
    ${props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${props => props.theme.palette.colors.black};
    ${props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({
    width: '100%',
    height: 420
})`             
`;

export const StatusBadge = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.02em; 
    text-align: left;
    color: ${props => props.theme.palette.status[props.background]};
`;

