import styled from "styled-components";

export const AnticipationContent = styled.div.attrs({})`
    padding: 15px 16px;
    display: flex;
    flex-direction: column;
    background: ${p => p.theme.palette.colors.white};
    border-radius: 4px;
    justify-content: center;
    ${p => p.row ? `
        flex-direction: row;
        ` : ``};
`;

export const AnticipationTitle = styled.div.attrs({})`
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: ${p => p.theme.palette.colors.black};
`;

export const AnticipationValue = styled.div.attrs({})`
    font-family: Roboto;
    font-size: ${p => p.small ? '26px;' : '32px;'};
    font-weight: 700;
    line-height: 37.5px;
    text-align: left;
    color: ${p => p.theme.palette.info[p.color]};
`;
export const AnticipationInfo = styled.div.attrs({})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: left;
    color: ${p => p.theme.palette.colors.black};
    display: flex;
    align-items: center;
`;

export const FormBorder = styled.div.attrs({})`
    margin: 6px 16px;    
    border-left: 1px solid ${p => p.theme.palette.colors.border};
`;
export const TitleContent = styled.div.attrs({})``;