import Button from "components/Form/Button";
import styled from "styled-components";
import { theme } from "ui/theme-color";

export const Title = styled.div.attrs({
})`            
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: ${props => props.theme.palette.primary.main};
    ${props => props.centred ? `
            text-align: center;
        ` : ``
  }
`;

export const FormSpacing = styled.div.attrs({
})`
  margin-top: 12px;
  margin-bottom: 12px;
  border-bottom: .5px solid ${props => props.theme.palette.primary.lightGrey};
`;

export const FilterSidebar = styled.div.attrs({
})` 
  display: flex;
  flex-direction: column;
  max-width: 384px;
  width: 100%;
  height: 100%;
  top: 0;
  right: -400px;
  position: fixed;
  padding: 24px;
  background-color: ${props => props.theme.palette.colors.white};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5); 
  transition: right 0.3s ease-in-out; 
  z-index: 1000; 
  overflow-y: auto;
  &.active {
    right: 0; 
  }
`;

export const Overlay = styled.div.attrs({
})`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 900;  
  &.active {
    display: block;  
  }
`;
export const FormSpace = styled.div.attrs({
})`
  flex: 1;
`;
