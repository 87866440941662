import React, { useContext, useEffect, useMemo, useRef, useState } from "react";


import ContainerAuthenticated from "containers/Authenticated";
import { useHistory } from "react-router-dom";
import WrapperContent from "components/WrapperContent";
import FormWrapper from "components/FormWrapper";
import FormCore from "../../components/Form/Core";
import { optionsAccountType } from "utils/options";
import { Create, ReadEmployeeCompanyByCPF, ReadOne, Update } from "services/employees";
import { exposeStrapiError, normalizeStrapiRegister } from "utils";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { ReadMe } from "services/authentication";
import { useParams } from "react-router-dom";



export default function EmployeesForm() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    
    const { id } = useParams()

    const { user } = useContext(CoreContext)
    
    const [loading, setLoading] = useState(false);
    const [register, setRegister] = useState(null);
    const [registerContact, setRegisterContact] = useState(null);
    const [registerBank1, setRegisterBank1] = useState(null);
    const [registerBank2, setRegisterBank2] = useState(null);

    const formRef = useRef()
    const formContactRef = useRef()
    const formBank1Ref = useRef()
    const formBank2Ref = useRef()

    const formItems = useMemo(() => {
        return [
            {
                ref: 'full_name',
                type: 'text',
                placeholder: 'Nome completo',
            },
            {
                ref: 'rg',
                type: 'text',
                placeholder: 'RG',
            },
            {
                ref: 'cpf',
                type: 'text',
                placeholder: 'CPF',
                mask: '999.999.999-99',

            },
            {
                ref: 'position',
                type: 'text',
                placeholder: 'Cargo',

            },
            {
                ref: 'salary',
                type: 'money',
                placeholder: 'Salário mensal (R$)',

            },
            {
                ref: 'employee_days_worked',
                type: 'number',
                placeholder: 'Dias trabalhados',

            },
        ];
    }, []);

    const contactFormItems = useMemo(() => {
        return [
            {
                ref: 'phone',
                type: 'text',
                placeholder: 'DDD + Telefone fixo',
                mask: '(99) 99999-9999',
            },
            {
                ref: 'celphone',
                type: 'text',
                placeholder: 'DDD + Telefone celular',
                mask: '(99) 99999-9999',

            },
            {
                ref: 'email',
                type: 'text',
                placeholder: 'E-mail',
            },
        ];
    }, []);

    const bankFormItems = useMemo(() => {
        return [
            {
                ref: 'bank',
                type: 'number',
                placeholder: 'Banco',
                quarter: true,

            },
            {
                ref: 'agency',
                type: 'number',
                placeholder: 'Agência',
                six: true,

            },
            {
                ref: 'account',
                type: 'number',
                placeholder: 'Conta corrente',
                six: true,

            },
            {
                ref: 'type', 
                placeholder: 'Tipo de conta',
                six: true,
                options: optionsAccountType
            },
            {
                ref: 'pix_key',
                type: 'text',
                placeholder: 'Chave pix',
                quarter: true,
            },
        ];
    }, []); 

    const buttons = [
        {
            action: () => history.goBack(),
            label: 'Cancelar',
            outlineGradient: true,
        },
        {
            action: () => save(),
            label: 'Salvar',
            gradient: true,
            loadable: true,
        },
    ];


    const valid = (payload, array) => {
        for (let item of array) {
            if (item?.ref && !payload?.[item?.ref]) {
                return false;
            }
        }
        return true;
    };

    const save = async () => {
        const form = formRef?.current?.getForm()
        const formContact = formContactRef?.current?.getForm()
        const formBank1 = formBank1Ref?.current?.getForm()
        const formBank2 = formBank2Ref?.current?.getForm()

        if(!valid(form, formItems)){
            toast.error("Preencha os dados do funcionário")
            return;
        }

        if(!valid(formContact, contactFormItems)){
            toast.error("Preencha os contatos")
            return;
        }

        if(!valid(formContact, contactFormItems)){
            toast.error("Preencha os dados bancários")
            return;
        }

        const payload = {
            ...form,
            company: user?.company?.id,
            contact: formContact,
            bank_details:[
                formBank1,
                formBank2
            ]
        }

        setLoading(true)
        const result = id ? await Update({ data: payload }, id) : await Create({ data: payload })
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            toast.success("Salvo com sucesso")
            history.goBack()
        }

    }

    const init = async () => { 
        if(id){ 
            const result = await ReadOne(id)
            const normalResult = normalizeStrapiRegister(result) 
    
            setRegister(normalResult)
            setRegisterBank1(normalResult?.bank_details?.[0])
            setRegisterBank2(normalResult?.bank_details?.[1])
            setRegisterContact(normalResult?.contact)
        }
    }
    
    useEffect(() => { init() ;}, [id])

    return (
        <>
            <ContainerAuthenticated>
                <WrapperContent title={'Detalhes sobre o funcionário'} loading={loading} buttons={buttons} back footer>
                    <FormWrapper title={"Dados do  funcionário"}>
                        <FormCore ref={formRef} formItems={formItems} register={register} noSpace />
                        <FormWrapper title={"Contatos"} noSpace>
                            <FormCore ref={formContactRef} formItems={contactFormItems} register={registerContact} noSpace />
                        </FormWrapper>
                    </FormWrapper>
                    <FormWrapper title={"Dados bancários - Conta 1"} >
                        <FormCore ref={formBank1Ref} formItems={bankFormItems} register={registerBank1} noSpace />
                    </FormWrapper>
                    <FormWrapper title={"Dados bancários - Conta 2"} >
                        <FormCore ref={formBank2Ref} formItems={bankFormItems} register={registerBank2} noSpace />
                    </FormWrapper>
                </WrapperContent>
            </ContainerAuthenticated>
        </>
    );
}