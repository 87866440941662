import React, { useContext, useEffect, useMemo, useState } from "react";

import {
    DashboardAnimation,
    DashboardTitle,
    DashboardText
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import useWindowSize from "utils/useWindowSize";
import WrapperContent from "components/WrapperContent";
import AnticipationInfo from "components/AnticipationInfo";
import AreaChart from "components/AreaChart";
import HomeTable from "components/HomeTable";
import FilterForms from "components/FilterForms";
import { ReadHomeAdmin } from "services/bff";
import { parseCurrency } from "utils/parsers";
import { saveAsPDF } from "utils";

export default function DashboardHome() {

    const { setModal, setOpened } = useContext(CoreContext)
    const { width } = useWindowSize()

    const [infos, setInfos] = useState(null);

    const [filters, setFilters] = useState(null);
    const [isActive, setIsActive] = useState(false);

    const handleOpenModal = () => {
        setModal({
            type: 'sample',
            // ...anything you need
        })
    }

    const buttons = [
        {
            action: () => saveAsPDF("printable", "Relatório Antecipei"),
            label: 'Relatório',
            outlineGradient: true,
        },
        {
            action: () => setIsActive(true),
            label: 'Filtros',
            leftIcon: 'filter',
            outlineGradient: true,
        },
    ]

    const formItems = useMemo(() => {
        return [
            {
                ref: 'startDate',
                type: 'date',
                placeholder: 'Data início',
                full: true,
            },
            {
                ref: 'endDate',
                type: 'date',
                placeholder: 'Data fim',
                full: true,
            },
        ];
    }, [])

    const items = useMemo(() => {
        return [
            {
                title: 'Funcionários cadastrados',
                value: infos?.employees || 0,
                color: 'secondary',
            },
            {
                title: 'Funcionários que utilizaram',
                value: infos?.employees_requests || 0,
                color: 'tertiary',
            },
            {
                title: 'Antecipações realizadas',
                value: infos?.employees_orders || 0,
                color: 'quaternary',
            },
            {
                title: 'Valor antecipado',
                value: parseCurrency(infos?.payd_value || 0),
                color: 'primary',
                half: true,
            },
            {
                title: 'Valor pendente',
                value: parseCurrency(infos?.pending_value || 0),
                color: 'primary',
                half: true,
            },
        ]
    }, [infos])

    const init = async () => {
        const result = await ReadHomeAdmin(filters)
        setInfos(result)
    }

    useEffect(() => { init() ;}, [filters])

    return (
        <>
            <ContainerAuthenticated>
                <div id="printable">
                    <WrapperContent title={'Resumo'} buttons={buttons}>
                        <AnticipationInfo Items={items} />
                        {
                            !infos ? null : <>
                                <AreaChart data={infos?.monthsData} />
                                <HomeTable infos={infos} />
                            </>
                        }
                    </WrapperContent>
                    <FilterForms formItems={formItems} setFilters={setFilters} isActive={isActive} setIsActive={setIsActive} />
                </div>
            </ContainerAuthenticated>
        </>
    );
}