import styled from 'styled-components'

export const FormTitle = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.primary.main}; 
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: ${p => p.center ? 'center' : ''};

`;
export const FormText = styled.div.attrs({
})`           
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: ${p => p.center ? 'center' : ''};
    color: ${props => props.theme.palette.colors.grey};
`;

export const FormSpacer = styled.div.attrs({
})`           

    ${p => p.border ? `
        margin-bottom: 8px;
        border: 0.5px solid ${p.theme.palette.colors.border};
        ` : ''};
`;

export const CreatePasswordContent = styled.div.attrs({})`
    width: 100%;
    display: flex;
    padding: 40px;
    gap: 16px;
    height: fit-content;
    align-items: ${p => p.center ? 'center' : ''};
    justify-content: ${p => p.center ? 'center' : ''};
    flex-direction: column;
    background: ${p => p.theme.palette.colors.white};
    border-radius: 8px;
    @media (max-width: 768px) {
        padding: 20px;
    }
     ${p => p.success ? `
            height: auto;
            align-items: center;
             justify-content: center;
        `: ``}   

`;

export const InputContent = styled.div.attrs({})`
    display: flex;
    gap: 24px;
    width: 100%;
`;