import styled from "styled-components";

export const ChartContainer = styled.div.attrs({})`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 4px;
  gap: 24px;
  background: ${p => p.theme.palette.colors.white};
`;

export const ChartTitle = styled.div.attrs({})`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  padding-left: 16px;
  color: ${p => p.theme.palette.colors.black};
`;