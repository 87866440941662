import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { theme } from 'ui/theme-color';
import { ChartContainer, ChartTitle } from './styled';

const AreaChart = ({ data }) => {
 
  
  const records =  data?.length ? data : []

  const series = useMemo(() => [
    {
      name: 'Funcionários cadastrados',
      data: records?.map(m => m.employees_registered)
    },
    {
      name: 'Utilizaram',
      data: records?.map(m => m.used)
    },
    {
      name: 'Realizadas',
      data: records?.map(m => m.realized)
    },
  ], []); 

  const options = useMemo(() => ({
    chart: {
      height: 350,
      type: 'area',
      stacked: true
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: records?.map(m => m.date)
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      },
    },
    colors: [theme.palette.info.secondary, theme.palette.info.tertiary, theme.palette.info.quaternary],
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    }
  }), [data]);

  return (
    <ChartContainer id="chart">
      <ChartTitle>
        Gráficos
      </ChartTitle>
      <Chart options={options} series={series} type="area" height={350} width="100%" />
    </ChartContainer>

  );
}

export default AreaChart;