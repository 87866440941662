import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import FormCore from "../Form/Core";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CoreContext } from "context/CoreContext";
import { ReadOne } from "services/core";
import { FilterSidebar, FormSpacing, Title, Overlay, FormSpace } from "./styled";
import Button from "components/Form/Button";
import { ContentIcon, Icon } from "ui/styled";

export default function FilterForms({ filters, setFilters, formItems, refForm, isActive, setIsActive }) {
  const formRef = useRef()
  const [register, setRegister] = useState(filters ?? null);


  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const apply = () => {
    const form = formRef?.current?.getForm()
    setFilters(form)
    setIsActive(false)
  }

  const clear = () => { 
    setRegister({}); 
    setFilters(null); 
    setIsActive(false)
  }

  return (
    <>
      <FilterSidebar className={isActive ? 'active' : ''}>
        <ContentIcon between>
          <Title>Filtros</Title>
          <Icon icon='close-black' onClick={toggleSidebar} pointer nomargin />
        </ContentIcon>
        <FormSpacing />
        <FormCore ref={formRef} formItems={formItems} register={register} />
        <FormSpace />
        <Button gradient onClick={apply}>Filtrar</Button>
        <Button onClick={clear} outlineGradient margin={'10px 0'} >Limpar</Button>
      </FilterSidebar>
      <Overlay className={isActive ? 'active' : ''} onClick={toggleSidebar} />
    </>
  );
}