import styled from "styled-components";

export const HeaderOptions = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border-radius: 4px;
    background: ${p => p.theme.palette.colors.lightgrey};
    width: 312px;
    position: absolute;
    top: 100%;
    right: 40px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    z-index: 2;
`;
export const HeaderOptionsContent = styled.div.attrs({})`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const HeaderItem = styled.div.attrs({})`
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 4px;
    background: ${p => p.theme.palette.colors.white};
    width: 100%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: ${p => p.theme.palette.primary.main};
    ${p => p.space ? 'margin-top: 4px;' : ''};
    cursor: pointer;
    &:hover {

    }
`;