import React from 'react'
import { HeaderItem, HeaderOptions, HeaderOptionsContent } from './styled'
import { DoLogout } from 'services/authentication';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function HeaderUserOptions() {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const menuItems = [
    {
      label: "Minha conta",
      page: 'profile'
    },
    {
      label: "Senha",
      page: 'password'
    },
  ];

  const exit = async () => {
    await DoLogout()
    navigate('login')
  }


  return (
    <>
      <HeaderOptions>
        <HeaderOptionsContent>
          {
            menuItems.map((item, index) => (
              <HeaderItem key={index}
                onClick={() => navigate(item?.page)}
              >
                {item.label}
              </HeaderItem>
            ))
          }
          <HeaderItem
            space
            onClick={exit}
          >
            Sair
          </HeaderItem>
        </HeaderOptionsContent>
      </HeaderOptions>
    </>
  )
}
