import { toast } from 'react-toastify';
import { API_ENDPOINT } from 'services/api'


import jsPDF from "jspdf";
import { toPng } from "html-to-image";


export const exposeStrapiError = result => { 

    if( !result ){
        toast.error( 'Cant connect to server, try again later')
        return true;
    }
    
    if(result?.error?.details?.message){
        toast.error(result?.error?.details?.message )
        return true;
    }
    
    if(result?.error?.details?.message){
        toast.error(result?.error?.details?.message )
        return true;
    }

    if(result?.error && result?.data?.[0]?.messages?.[0]?.message && typeof result?.data?.[0]?.messages?.[0]?.message === 'string'){
        toast.error(result?.data?.[0]?.messages?.[0]?.message )
        return true;
    }else if(result?.error && result?.message?.[0]?.messages?.[0]?.message && typeof result?.message?.[0]?.messages?.[0]?.message === 'string'){
        toast.error(result?.message?.[0]?.messages?.[0]?.message )
        return true;
    }else if(result?.error && result?.data?.[0]?.message && typeof result?.data?.[0]?.message === 'string'){
        toast.error(result?.data?.[0]?.message )
        return true;
    }else if(result?.error && result?.message && typeof result?.message === 'string'){
        toast.error(result?.message )
        return true;
    }else if(result?.error && result?.error?.message && typeof result?.error?.message === 'string'){
        toast.error(result?.error?.message )
        return true;
    }

    if(result?.error && result?.error?.details?.errors?.length ){
        result?.error?.details?.errors?.map( item => {
            toast.error(item?.message )
            return true;
        })
        return true;
    }
    
    return false;
}
 
export const normalizeStrapiList = result => {
    return result?.data?.length ? result?.data.map(item => ({
        id: item.id,
        ...item.attributes
    })) : [] 
}
 
export const normalizeStrapiRegisterSolo = result => { 
    return result?.id && result?.attributes ? { 
        id: result?.id,  
        ...result?.attributes
    } : result?.data?.id ? result?.data?.attributes ? {
        id: result?.data?.id,  
        ...result?.data?.attributes
    } : result?.data : result
}

export const normalizeStrapiRegister = result => { 
    let results = normalizeStrapiRegisterSolo(result)  
    Object.keys(results).map(attrKey => {
        results[attrKey] = results[attrKey]?.data ? normalizeStrapiRegisterSolo(results[attrKey]) : results[attrKey]
        results[attrKey] = typeof results[attrKey]?.data === 'object' ? results[attrKey]?.data : results[attrKey]
        results[attrKey] = results[attrKey]?.length && results[attrKey]?.[0]?.attributes ? 
            results[attrKey]?.map(normalizeStrapiRegisterSolo)
        : results[attrKey] 


        return true;
    }) 
    return results;
}
 
export const normalizeStrapiPayload = (form, formtypes) => {

    formtypes.map(item => {
        if(form[item.name]){ 
            if((item.type === 'float' || item.type === 'decimal') && item.name === 'price' && typeof form[item.name] === 'string' ){
                form[item.name] = parseFloat(form[item.name].replace(/R|\$|\.| /g, '').replace(',','.'))
            }
    
            if( item.type === 'float' || item.type === 'decimal' ){
                form[item.name] = parseFloat(form[item.name])
            }
    
            if( item.type === 'biginteger' || item.type === 'integer' ){
                form[item.name] = parseInt(form[item.name])
            }
            
            if( (item.type === 'date' || item.type === 'time' || item.type === 'datetime') && typeof form[item.name]?.getFullYear === 'function' ){
                form[item.name] = parseStrapiDate(form[item.name])
            }
            
            if( item.type === 'time' ){
                form[item.name] = (`${form[item.name]}`.split("T")).pop() 
            }
            
            if( item.type === 'date' ){
                form[item.name] = `${(`${form[item.name]}`.split("T")).shift()}` 
            } 
            
        }
        return true;
    })

    return { ...form, data:{...form}}
}

export const numerize = number => {
    return parseInt(number) < 10 ? `0${number}` : `${number}`
}

export const parseStrapiDate = date => {  
    return typeof date?.getFullYear !== 'function' ? date : `${date.getFullYear()}-${numerize(date?.getMonth()+1)}-${numerize(date?.getDate())}T${numerize(date?.getHours())}:${numerize(date?.getMinutes())}:${numerize(date?.getSeconds())}`
} 
 
export const parseStrapiImage = url => {
    return url?.indexOf('://') !== -1 ? url : `${API_ENDPOINT.replace('/api', '')}${url}`
}

export const findShowableParam = item => {
    
    const notAllowed = [ 
        'id',
        'createdAt',
        'updatedAt',
        'createdBy',
        'updatedBy',
        'publishedAt',
        'attributes',
        'created_by',
        'updated_by',
        'created_at',
        'updated_at',
        'published_by',
        'published_at',
    ]
    return Object.keys(item).filter(fit => !notAllowed.includes(fit) ).shift()
}

export const filterSystemParams = (fit, attrs, item) => {

    const notAllowed = [ 
        'id',
        'createdAt',
        'updatedAt',
        'createdBy',
        'updatedBy',
        'publishedAt',
        'created_by',
        'updated_by',
        'created_at',
        'updated_at',
        'published_by',
        'published_at',
    ]

    if(item.uid.indexOf('plugin::') !== -1){
        notAllowed.push('provider')
        notAllowed.push('role')
    } 

    return !notAllowed.includes(fit) && (!attrs.private || attrs.type === 'password')
} 

export const capitalize = (s) => {
    if(!s){ return `` ;}
    let vs = `${s}`
    return `${ vs.charAt(0).toUpperCase() }${vs.slice(1)}`;
}

export const objectToQueryString = (obj, parentKey = '') => {
    let queryString = '';

    Object.keys(obj).forEach(key => {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;

        if (Array.isArray(obj[key])) {
            obj[key].forEach((value, index) => {
                queryString += `${fullKey}[${index}]=${value}&`;
            });
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            queryString += objectToQueryString(obj[key], fullKey);
        } else {
            queryString += `${fullKey}=${obj[key]}&`;
        }
    });
    
    if (queryString.endsWith('&')) {
        queryString = queryString.slice(0, -1);
    }

    return queryString;
}

export const calculateTaxes = (value, balance) => {
 
    const taxRate = ((balance?.tax||0)/100)
    const interestRate = ((balance?.interest||0)/100)
    
    const taxValue = (value||0) * taxRate
    const interestValue = (value||0) * interestRate 
    
    return ( taxValue + interestValue )
}

 

const loadFontAsBinary = async  (url) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    const binaryString = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
    return binaryString
}

export const saveAsPDF = (target, fname) => {
    // setOpenToPrint(true)
    setTimeout(() => { 
        const div = document.getElementById(target); 

        console.log("div", div, target)
        
        const divHeight = div.offsetHeight
        const divWidth = div.offsetWidth

        toPng(div)
            .then( (dataUrl) => { 
                const img = new Image();
                img.src = dataUrl;
                img.onload = async () => {

                    const dcanvas = document.createElement("div");
                    dcanvas.id = "reportCanvas"
                    document.body.append(dcanvas)
                    const canvasDiv = document.getElementById("reportCanvas");

                    const canvas = document.createElement("canvas");
                    
                    canvas.id = "canvasPrint"
                    canvas.width = divWidth
                    canvas.height = divHeight
                    const ctx = canvas.getContext("2d");

                    canvasDiv.append(canvas)

                    // ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, 0, 0, divWidth, divHeight);
                    const pdf = new jsPDF({
                        orientation: "landscape",
                        // unit: "in",
                        format: [divWidth, divHeight]
                    });

                    const myFont = await loadFontAsBinary('https://fonts.googleapis.com/css2?family=Poppins&display=swap')

                    pdf.addFileToVFS("Poppins.ttf", myFont);
                    pdf.addFont("Poppins.ttf", "Poppins", "normal");
                    pdf.setFont("Poppins");

                    const myFont2 = await loadFontAsBinary('https://fonts.googleapis.com/css2?family=Roboto&display=swap')

                    pdf.addFileToVFS("Roboto.ttf", myFont2);
                    pdf.addFont("Roboto.ttf", "Roboto", "normal");
                    pdf.setFont("Roboto");

                    pdf.addImage(canvas.toDataURL(), 'PNG', 0, 0, divWidth, divHeight);
                    pdf.save(`${fname}.pdf`);
                };

                setTimeout(() => {
                    const canvasPrintDiv = document.getElementById("canvasPrint");
                    canvasPrintDiv.parentNode.removeChild(canvasPrintDiv)
                    const canvasPrintParentDiv = document.getElementById("reportCanvas");
                    document.body.removeChild(canvasPrintParentDiv)
                    // div.style.fontSize = "14px;"
                    // setOpenToPrint(false)
                }, 1)

            }).catch((error) => {
                console.error('Error:', error);
            });
    }, 100  )
}