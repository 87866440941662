import React, { useContext, useEffect, useMemo, useState } from "react";

import ContainerAuthenticated from "containers/Authenticated";

import WrapperContent from "components/WrapperContent";
import AnticipationInfo from "components/AnticipationInfo";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import FormWrapper from "components/FormWrapper";
import BasicTable from "components/Form/Table";
import { ReadOne } from "services/company-orders";
import { normalizeStrapiRegister, numerize, parseStrapiImage } from "utils";
import { parseCurrency } from "utils/parsers";
import { ENUM_MONTHS, ENUM_STATUS } from "utils/options";
import moment from "moment/moment";

export default function OrderDetails() {

    const { id } = useParams(); 

    const [register, setRegister] = useState(null);

    const buttons = useMemo(() => {
        return [
            !register?.bank_slip?.url ? null : {
                action: () => window.open( parseStrapiImage(register?.bank_slip?.url), "new"),
                label: 'Visualizar boleto',
                outlineGradient: true,
            },
        ].filter(f => f)
    }, [register])

    const items = useMemo(() => {
        return [
            {
                title: 'Mês referência',
                value: ENUM_MONTHS[register?.month_ref],
                color: 'primary',
                twenty: true,
            },
            {
                title: 'VENCIMENTO',
                value: moment(register?.due_date)?.format("L"),
                color: 'primary',
                six: true,
            },
            {
                title: 'Antecipações realizadas',
                value: numerize(register?.antecipations?.length),
                color: 'primary',
                quarter: true,
            },
            {
                title: 'Status',
                value: ENUM_STATUS[register?.status],
                color: 'primary',
                six: true,
            },
            {
                title: 'Valor da ordem',
                value: parseCurrency(register?.order_value),
                color: 'primary',
                twenty: true,
            },
        ]
    }, [register])

    const columns = [
        { title: 'FUNCIONÁRIO', ref: 'employeeName', icon: true, noWrap: true, width: '40%' },
        { title: 'CPF', ref: 'cpf', icon: true, noWrap: true, width: '15%' },
        { title: 'DATA SOLICIT.', ref: 'requestDate', icon: true, noWrap: true, width: '15%' },
        { title: 'DATA ANTEC.', ref: 'advanceDate', icon: true, noWrap: true, width: '15%' },
        { title: 'VALOR ANTECIPADO', ref: 'value', icon: true, noWrap: true, width: '15%' },
    ];

    const rows = useMemo(() => {
        return (register?.antecipations||[])?.map(m => ({
            ...m, 
            requestDate: moment(m?.createdAt)?.format("L"),
            advanceDate: moment(m?.updatedAt)?.format("L"),
            raw_value: m?.value,
            value: parseCurrency(m?.value),
            employeeName: m?.employee?.data?.attributes?.full_name,
            cpf: m?.employee?.data?.attributes?.cpf,
        }))
    })
     
    const totalColumns = [
        { title: 'ordens selecionadas', ref: 'selectorder', icon: true, noWrap: true, width: '15%' },
        { title: '', ref: '', width: '70%', align: 'center' },
        { title: 'Valor total', ref: 'total', icon: true, noWrap: true, width: '15%' },
    ];

    const totalRow = useMemo(() => {
        return [
            {
                selectorder: numerize(register?.antecipations?.length),
                total: parseCurrency( register?.antecipations?.reduce((p, c) => p + (c?.value||0) , 0) ),
            },
        ]
    }, [register])

    const init = async () => { 
        if(id){ 
            const result = await ReadOne(id)
            const normalResult = normalizeStrapiRegister(result)
    
            setRegister({
                ...normalResult,
            })
        }
    }
    
    useEffect(() => { init() ;}, [id])

    return (
        <>
            <ContainerAuthenticated>
                <WrapperContent title={`Detalhe da ordem - ${id}`} buttons={buttons}>
                    <AnticipationInfo Items={items} small />
                    <FormWrapper title={"Antecipações solicitadas"}>
                        <BasicTable columns={columns} rows={rows} tableMin={800} />
                        <FormWrapper title={"Total da ordem"} noSpace>
                            <BasicTable columns={totalColumns} rows={totalRow} tableMin={800} />
                        </FormWrapper>
                    </FormWrapper>
                </WrapperContent>
            </ContainerAuthenticated>
        </>
    );
}