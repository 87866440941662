import styledCmp from 'styled-components'

import { styled } from '@mui/material/styles';
import Input from '@mui/material/Input'; // standard (material)
// import Input from '@mui/material/FilledInput'; 
// import Input from '@mui/material/OutlinedInput'; 


export const MaterialInput = styled(Input)(({ theme, ...props }) => ({
  background: props.outline ? 'transparent' : theme.palette.colors.lightgrey,
  height: props.outline ? 40 : 48,
  borderRadius: props.outline ? 4 : 2,
  padding: '0 8px',
  border: !props.outline ? 'none' : `1px solid ${theme.palette.colors.border}`,

}));

export const InputIcon = styledCmp.img.attrs({
})`
`;

export const InputContainer = styledCmp.div.attrs({})`
  width: 100%;
`;