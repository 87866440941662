import React, { useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer,
    CreatePasswordContent,
    InputContent
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoResetPassword } from "services/authentication";
import { exposeStrapiError } from "utils";
import { ButtonContainer, Icon } from "ui/styled";
import PasswordValidation from "components/Form/PasswordValidation";

export default function CreatePassword() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const params = new URLSearchParams(window.location.search)
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(0)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const valid = (verbose = false) => {

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error('Preencha o campo: Nova senha'); }
            return false;
        }

        if (!formValue('cpassword') || !formValue('cpassword').length) {
            if (verbose) { toast.error('Preencha o campo: Confirmar nova senha'); }
            return false;
        }

        if (formValue('password') !== formValue('cpassword')) {
            if (verbose) { toast.error('Nova senha e confirmação da nova senha não são iguais'); }
            return false;
        }

        return true
    }

    const action = async () => {
        if (!valid(true)) { return; }
        setLoading(true)

        const result = await DoResetPassword({
            code: params.get('code'),
            password: formValue('password'),
            passwordConfirmation: formValue('cpassword')
        })

        setLoading(false)
        if (result && !exposeStrapiError(result)) {
            completNext()
        }
    }

    const completNext = () => {
        toast.success('Senha criada com sucesso');
        setStep(step + 1);
    }

    const steps = useMemo(() => [
        {
            title: 'Nova senha',
            text: 'Digite uma nova senha para sua conta',
            label: 'Salvar',
        },
        {
            title: 'Nova senha criada com sucesso',
            text: 'Agora você já pode fazer seu login com sua nova senha',
            label: 'Fechar',
        },

    ], []);

    const next = () => {
        if (step === steps.length - 1) {
            //save data
            navigate('Login')
            return;
        }
        if (step === steps.length - 2) {
            action()
            return;
        }
        setStep(step + 1);
    } 

    return (
        <>
            <ContainerUnauthenticated>
                <CreatePasswordContent success={step === 1}>
                    {!(step === 1) ? null : <><Icon icon={"success"} /></>}
                    <FormTitle center={step === 1}>{steps?.[step]?.title}</FormTitle>
                    <FormText center={step === 1}>{steps?.[step]?.text}</FormText>
                    {!(step === 0) ? null : <>
                        <InputContent>
                            <Input id={'password'} placeholder="Nova Senha" type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} />
                            <Input id={'cpassword'} placeholder="Confirmar nova Senha" type="password" value={formValue('cpassword')} onChange={e => changeForm(e.target.value, 'cpassword')} />
                        </InputContent>
                        <PasswordValidation password={formValue('password')} />
                        <FormSpacer border />
                        <ButtonContainer end>
                            <Button gradient width={"fit-content"} rigthIcon={"chevron-white"} nospace loading={loading} onClick={next}>{steps?.[step]?.label}</Button>
                        </ButtonContainer>
                    </>}
                    {!(step === 1) ? null : <>

                        <FormSpacer border />
                        <ButtonContainer center>
                            <Button width={"298px"} outline gradient nospace loading={loading} onClick={next} >{steps?.[step]?.label}</Button>
                        </ButtonContainer>

                    </>
                    }
                </CreatePasswordContent>
            </ContainerUnauthenticated>
        </>
    );
}