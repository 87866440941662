import React, { useContext, useEffect, useMemo, useState } from "react";

import ContainerAuthenticated from "containers/Authenticated";
import { CoreContext } from "context/CoreContext";
import useWindowSize from "utils/useWindowSize";
import WrapperContent from "components/WrapperContent";
import HomeTable from "components/HomeTable";
import TableWrapper from "components/TableWrapper";
import BasicTable from "components/Form/Table";
import { Icon, IconContainer } from "ui/styled";
import FilterForms from "components/FilterForms";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

import { ReadHomeEmployee } from "services/bff";
import { Delete } from "services/employees";
import { parseCurrency } from "utils/parsers";
import { numerize } from "utils";


export default function Employees() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [registers, setRegisters] = useState(null);
    const [filters, setFilters] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [searchExpression, setSearchExpression] = useState("");

    const rows = useMemo(() => {
        return (registers || []);
    }, [registers])

    const columns = [
        { title: 'ID', ref: 'id', icon: true, noWrap: true, width: '10%', },
        { title: 'FUNCIONÁRIO', ref: 'full_name', icon: true, noWrap: true, width: '20%', },
        { title: 'CPF', ref: 'cpf', icon: true, noWrap: true, width: '15%', },
        { title: 'CARGO', ref: 'position', icon: true, noWrap: true, width: '15%', },
        { title: 'SALÁRIO', ref: 'salary', icon: true, noWrap: true, width: '15%', },
        { title: 'DIAS TRAB.', ref: 'days_worked', icon: true, noWrap: true, width: '10%', },
        { title: 'E-MAIL', ref: 'email', icon: true, noWrap: true, width: '20%', },
        {
            title: '', ref: 'actions', renderCell: ({ row }) =>
                <IconContainer>
                    <Icon icon='pencil' pointer nomargin onClick={() => navigate(`employees/${row?.id}`)} />
                </IconContainer>
            , width: '40px', flexGrow: 0
        },
        {
            title: '', ref: 'actions', renderCell: ({ row }) =>
                <IconContainer>
                    <Icon icon='trash' pointer nomargin onClick={() => remove(row?.id)} />
                </IconContainer>
            , width: '40px', flexGrow: 0
        },
        {
            title: '', ref: 'actions', renderCell: ({ row }) =>
                <IconContainer>
                    <Icon icon='chevron-right' pointer nomargin onClick={() => navigate(`employees/${row?.id}`)} />
                </IconContainer>
            , width: '40px', flexGrow: 0
        },
    ];

    const formItems = useMemo(() => {
        return [
            {
                ref: 'position',
                type: 'text',
                placeholder: 'Cargo',
                full: true,
            },
            {
                ref: 'raw_salary',
                type: 'number',
                placeholder: 'Salário',
                full: true,
            },
        ];
    }, [rows])

    const buttons = [
        {
            action: () => navigate('employees/form'),
            label: 'Novo',
            leftIcon: 'plus',
            gradient: true,
            small: true,
        },
    ]

    const tableButtons = [
        {
            action: () => setIsActive(true),
            label: 'Filtros',
            leftIcon: 'filter',
            outlineGradient: true,
        }
    ]

    const filterFilters = item => {
        return !filters || (
            Object?.keys(filters)?.some(ref =>
                `${item?.[ref]}`?.toLowerCase()?.indexOf(`${filters?.[ref]}`?.toLowerCase()) !== -1
            )
        )
    }

    const remove = async id => {
        const result = await Delete(id)
        if (result) {
            toast.success("Removido com sucesso")
            init();
        }
    }

    const init = async () => {
        const result = await ReadHomeEmployee()
        if (result?.length) {
            setRegisters(result?.map(m => ({
                ...m,
                raw_salary: (m?.salary), 
                salary: parseCurrency(m?.salary), 
            })))
        }
    };

    useEffect(() => { init(); }, []);

    const filterExpression = item => {
        return (!searchExpression || Object.keys(item).filter(k => `${item[k]}`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1).length > 0)
    }

    return (
        <>
            <ContainerAuthenticated>
                <WrapperContent title={'Funcionários'} buttons={buttons} back>
                    <TableWrapper
                        buttons={tableButtons}
                        setSearchExpression={setSearchExpression}
                        totalResults={rows?.filter(filterFilters)?.filter(filterExpression)?.length}
                        search >
                        <BasicTable columns={columns} rows={rows?.filter(filterFilters)?.filter(filterExpression)} pagination tableMin={800} />
                    </TableWrapper>
                </WrapperContent>
                <FilterForms formItems={formItems} setFilters={setFilters} filters={filters} isActive={isActive} setIsActive={setIsActive} />
            </ContainerAuthenticated>
        </>
    );
}