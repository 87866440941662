import styled from 'styled-components'

export const LoginContainer = styled.div.attrs({})`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
`;

export const LoginContent = styled.div.attrs({})`
    max-width: 436px;
    width: 100%;
    display: flex;
    padding: 40px;
    flex-direction: column;
    background: ${p => p.theme.palette.colors.white};
    border-radius: 8px;
    gap: 16px;
    @media (max-width: 424px) {
        padding: 20px;
    }

`;
export const FormTitle = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.primary.main}; 
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;

`;
export const FormText = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};

    max-width: 240px;
    margin-bottom: 30px;
`;

export const FormSpacer = styled.div.attrs({
})`           
    margin-top: 40px;
`;

export const RegisterForgot = styled.div.attrs({
})`           
    margin-top: 40px;
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    display: flex;
`;

export const ForgotLink = styled.div.attrs({
})`           

    color: ${props => props.theme.palette.primary.main};
    cursor: pointer;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    text-align: center;
    &:hover{
        text-decoration: underline;
    }
`;

export const RegisterCall = styled.div.attrs({
})`           
    margin: 30px 0 0px;
    color: ${props => props.theme.palette.colors.grey};
    font-size: 15;
    font-weight: bold;
`;
