import Input from 'components/Form/Input';
import React from 'react';
import { Icon } from 'ui/styled';
import { Content, FilterButton, InputContainer, ResultsCount, SearchContainer } from './styled';

const SearchComponent = ({ totalResults, onFilterClick, setSearchExpression }) => {
  const [searchValue, setSearchValue] = React.useState('');

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setSearchExpression(event.target.value);
  };

  return (
    <SearchContainer>
      <InputContainer>
        <Input
          type="text"
          placeholder="Pesquisar"
          value={searchValue}
          onChange={handleSearchChange}
          holder
          search
          outiline
        />
      </InputContainer>
      <ResultsCount>{totalResults} resultados encontrados</ResultsCount>
    </SearchContainer>
  );
};

export default SearchComponent;