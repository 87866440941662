import React, { useState } from 'react'
import { ContentHeaderTitleContainer, ContentWrapperContainer, ContentWrapperButtonContainer, ContentWrapperHeader } from './styled'
import { ContainerOutlined, FormSpacer, Title } from 'ui/styled'
import Button from 'components/Form/Button'
import Input from 'components/Form/Input'
import SearchComponent from 'components/Search'

export default function TableWrapper({ children, title, buttons, totalResults, setSearchExpression, search }) {
  const [searchValue, setSearchValue] = React.useState('');

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setSearchExpression(event.target.value);
  };

  return (
    <>
      <ContentWrapperContainer>
        <ContentWrapperHeader space={search}>
          {!title ? null :
            <ContentHeaderTitleContainer>
              <Title black nomargin small> {title} </Title>
            </ContentHeaderTitleContainer>
          }
          {!search ? null : <SearchComponent totalResults={totalResults} setSearchExpression={setSearchExpression} />}
          <ContentWrapperButtonContainer>
            {
              buttons?.map((m, k) => (
                <Button width='fit-Content' rigthIcon={m?.rigthIcon} leftIcon={m?.leftIcon} outlineGradient={m?.outlineGradient} gradient={m?.gradient} nospace onClick={m?.action} small>
                  {m?.label}
                </Button>
              ))
            }
          </ContentWrapperButtonContainer>
        </ContentWrapperHeader>
        {children}
      </ContentWrapperContainer>
    </>
  )
}
