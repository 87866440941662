import React, { useEffect, useMemo, useRef, useState } from "react";


import ContainerAuthenticated from "containers/Authenticated";
import { useHistory } from "react-router-dom";
import WrapperContent from "components/WrapperContent";
import FormWrapper from "components/FormWrapper";
import FormCore from "../../components/Form/Core";
import { Receipt } from "./styled";
import { ReadOne } from "services/employee-requests";
import { normalizeStrapiRegister, parseStrapiImage } from "utils";
import { useParams } from "react-router-dom";
import moment from "moment";
import { ENUM_STATUS } from "utils/options";


export default function AdvancementsDetails() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    
    const { id } = useParams()

    const [register, setRegister] = useState(null);

    const formRef = useRef()

    const formItems = useMemo(() => {
        return [
            {
                ref: 'name',
                type: 'text',
                placeholder: 'Nome completo',
                quarter: true,
                disabled: true
            },
            {
                ref: 'rg',
                type: 'text',
                placeholder: 'RG',
                quarter: true,
                disabled: true
            },
            {
                ref: 'cpf',
                type: 'text',
                placeholder: 'CPF',
                mask: '999.999.999-99',
                quarter: true,
                disabled: true

            },
            {
                ref: 'position',
                type: 'text',
                placeholder: 'Cargo',
                quarter: true,
                disabled: true

            },
        ];
    }, []);

    const detailsFormItems = useMemo(() => {
        return [
            {
                ref: 'requestDate',
                type: 'text',
                placeholder: 'Data da solicitação',
                quarter: true,
                disabled: true
            },
            {
                ref: 'advanceDate',
                type: 'text',
                placeholder: 'Data da antecipação',
                quarter: true,
                disabled: true

            },
            {
                ref: 'value',
                type: 'money',
                placeholder: 'Valor (R$)',
                quarter: true,
                disabled: true

            },
            {
                ref: 'status',
                type: 'text',
                placeholder: 'Status',
                quarter: true,
                disabled: true

            },
        ];
    }, []);

    const init = async () => { 
        if(id){ 
            const result = await ReadOne(id)
            const normalResult = normalizeStrapiRegister(result)
            
            setRegister({
                ...normalResult,
                rg: normalResult?.employee?.rg,
                cpf: normalResult?.employee?.cpf,
                position: normalResult?.employee?.position,
    
                requestDate: moment(normalResult?.createdAt)?.format('L'),
                advanceDate: moment(normalResult?.updatedAt)?.format('L'),
    
                status: ENUM_STATUS[normalResult?.status]
            })
        }
    }
    
    useEffect(() => { init() ;}, [id])

    return (
        <>
            <ContainerAuthenticated>
                <WrapperContent title={'Detalhes da antecipação'} back footer>
                    <FormWrapper title={"Dados do  funcionário"}>
                        <FormCore ref={formRef} formItems={formItems} register={register} noSpace />
                        <FormWrapper title={"Detalhe da antecipação salarial"} noSpace>
                            <FormCore ref={formRef} formItems={detailsFormItems} register={register} noSpace />
                        </FormWrapper>
                        {
                            !register?.payment_receipt?.url ? null :
                                <FormWrapper title={"Comprovante de transferência"} noSpace>
                                    <Receipt src={ parseStrapiImage(register?.payment_receipt?.url) } />
                                </FormWrapper>
                        }
                    </FormWrapper>
                </WrapperContent>
            </ContainerAuthenticated>
        </>
    );
}