import React from 'react'
import { FormSpacer, FormWrapperContainer } from './styled'
import { Title } from 'ui/styled'

export default function FormWrapper({ title, children, noSpace }) {
  return (
    <>
      <FormWrapperContainer noSpace={noSpace}>
        <Title small black nomargin>
          {title}
        </Title>
        <FormSpacer />
        {children}
      </FormWrapperContainer>
    </>
  )
}
