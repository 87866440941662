import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  mode: 'light',
  palette: {
    primary: {
      main: '#002C6A',
      contrastText: '#fff',
    },
    secondary: {
      main: '#e86c30',
      contrastText: '#fff',
    },
    white: {
      main: '#ffffff',
      contrastText: '#002C6A',
    },
    error: {
      main: '#dd4952',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#a4a4a4',
      contrastText: '#fff',
      primary: '#002C6A',
      secondary: '#EA028C',
      tertiary: '#6168B9',
      quaternary: '#4DEBB5',

    },
    success: {
      main: '#06C167',
    },
    gradient: {
      primary: '#F99E3F',
      secondary: '#F04537',
      contrastText: '#fff',
    },
    status: {
      'Pago': '#227F39',
      'Aprovado': '#0053C7',
      'Pendente': '#E38800',
      'Negado': '#FF0000',


      'paid': '#227F39',
      'appoved': '#0053C7',
      'pending': '#E38800',
      'refused': '#FF0000',
    },
    colors: {
      white: '#ffffff',
      black: '#000000',
      grey: '#4C4C4C',
      backgroundgrey: '#F5F5F5',
      lightgrey: '#F7F7F7',
      shadow: 'rgba(0,0,0,.16)',
      lightshadow: 'rgba(112,112,112,.06)',
      border: 'rgba(187, 187, 187, 0.6)',
      background: '#F2F2F2',
    }
  },
});  