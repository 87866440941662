import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import { Content, PaginationContainer, PaginationQuantity } from './styled';
import { Icon } from 'ui/styled';

const CustomPagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-actions': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0
  },
  '& .MuiButtonBase-root': {
    margin: theme.spacing(0.5),
    minWidth: 40,
    height: 40,
    backgroundColor: theme.palette.colors.background,
    color: theme.palette.colors.grey,

    '&.Mui-selected': {
      background: `linear-gradient(to right, ${theme.palette.gradient.primary}, ${theme.palette.gradient.secondary})`,
      color: theme.palette.colors.white,
    },
  },
  '& .MuiTablePagination-toolbar': {
    padding: 0,
    '& .MuiTablePagination-spacer': {
    },
    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-selectRoot': {
      display: 'none',
    },
  },
}));

export default function CustomTablePagination(props) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const [displayedRows, setDisplayedRows] = useState(0);

  useEffect(() => {
    const start = page * rowsPerPage;
    const end = Math.min(count, start + rowsPerPage);
    setDisplayedRows(end - start);
  }, [page, rowsPerPage, count]);

  return (
    <>
      <PaginationQuantity>
        {`Mostrando ${displayedRows} de ${count} resultados`}
      </PaginationQuantity>
      <CustomPagination
        component="div"
        count={count}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
        ActionsComponent={(subProps) => (
          <PaginationContainer>
            <Content className={subProps.className}>
              <Button
                onClick={(event) => subProps.onPageChange(event, subProps.page - 1)}
                disabled={subProps.page === 0}
              >
                <Icon icon="arrow-left" nomargin />
              </Button>
              {[...Array(Math.ceil(count / rowsPerPage)).keys()].map((pageNumber) => (
                <Button
                  key={pageNumber}
                  onClick={(event) => subProps.onPageChange(event, pageNumber)}
                  className={subProps.page === pageNumber ? 'Mui-selected' : ''}
                >
                  {pageNumber + 1}
                </Button>
              ))}
              <Button
                onClick={(event) => subProps.onPageChange(event, subProps.page + 1)}
                disabled={subProps.page >= Math.ceil(count / rowsPerPage) - 1}
              >
                <Icon icon="arrow-right" nomargin />
              </Button>
            </Content>
          </PaginationContainer>
          )}
        />

    </>

  );
}

