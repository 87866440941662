import styled from "styled-components";

export const SearchContainer = styled.div.attrs({
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  flex-wrap: wrap;
  gap: 16px;
`;

export const ResultsCount = styled.div.attrs({

})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.info.main};
  
`;

export const InputContainer = styled.div.attrs({
})`
    width: 320px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;