import styledCmp from 'styled-components'
import ReactLoading from 'react-loading'

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const Load = styledCmp(ReactLoading).attrs(props => ({
    type: 'spin',
    color: props.outline ?
        (
            props.primary ? props.theme.palette.primary.main :
                (props.secondary ? props.theme.palette.secondary.main : props.theme.palette.info.main)
        )
        : props.theme.palette.primary.contrastText,
    height: 20,
    width: 20
}))`          
`;

export const ColorButton = styled(Button)(({ theme, nospace, gradient, width, between, small }) => ({
    width: width ? width : '100%',
    minHeight: small ? '40px' : '48px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    justifyContent: between && 'space-between',
    fontSize: '18px',
    textTransform: 'none',
    marginTop: nospace ? '0px' : '12px',
    background: gradient ? `linear-gradient(to right, ${theme.palette.gradient.primary}, ${theme.palette.gradient.secondary})` : '',
    color: gradient ? theme.palette.gradient.contrastText : '',
    borderRadius: 4,
    display: "ruby"
}));


export const BorderGradient = styledCmp.div.attrs({
})`
    position: relative;
    width: ${props => props.width ? props.width : '100%'};
    margin-top: ${props => props.nospace ? '0px' : '12px'};
    z-index: 1;
    overflow: hidden;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
    line-height: 17px;
    justify-content: ${props => props.between ? 'space-between' : 'center'};
    min-height: ${p => p.small ? '40px' : '42px'};
    padding: 0 16px;
    background: linear-gradient(to right, ${props => props.theme.palette.gradient.primary}, ${props => props.theme.palette.gradient.secondary});
    color: ${props => props.theme.palette.colors.grey};
    transition: transform 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    &:hover {
        color: ${props => props.theme.palette.colors.primary};
        box-shadow: none;
    }

    &:before {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        background-color: ${props => props.theme.palette.colors.white};
        border-radius: 4px;
        z-index: -1;
        transition: background-color 0.3s ease, filter 0.3s ease;
    }

    &:hover:before {
        background-color: ${props => props.theme.palette.colors.white};
        filter: none;
    }
`;