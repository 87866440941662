import styledCmp from 'styled-components'

import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';

export const MaterialSelect = styled(Select)(({ theme, ...props }) => ({
  background: theme.palette.colors.lightgrey,
  height: 48,
  borderRadius: 2,
  padding: '0 8px',
}));
