import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { EmptyMessage, Icon, Load, LoadCenter } from 'ui/styled';

import { FormSpace, TitleContainer } from './styled';
import { Tooltip } from '@mui/material';
import CustomTablePagination from 'components/CustomTablePagination';

const StyledTableCell = styled(TableCell)(({ theme, noWrap, noBorder, flexGrow, width }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.colors.background,
    color: theme.palette.colors.black,
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 500,
    whiteSpace: noWrap ? 'nowrap' : 'normal',
    overflow: noWrap ? 'hidden' : 'visible',
    textOverflow: noWrap ? 'ellipsis' : 'clip',
    width: width ? width : 'auto',
    flexGrow: flexGrow ? flexGrow : 1,
    borderBottom: 'none',
    marginBottom: 4,
    textTransform: 'uppercase',
    padding: '11px 6px',
    height: 40,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: noWrap ? 'nowrap' : 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderBottom: noBorder ? 'none' : '',
    width: width ? width : 'auto',
    flexGrow: flexGrow ? flexGrow : 1,
    backgroundColor: theme.palette.colors.lightgrey,
    color: theme.palette.colors.black,
    marginTop: 4,
    height: 48,
    padding: '11px 6px',
    display: 'block',
  },
}));

const StyledTable = styled(Table)({
  tableLayout: 'fixed',
  width: '100%',
});

const FlexTableRow = styled(TableRow)({
  display: 'flex',
  gap: '4px',
});

export default function BasicTable({ columns, rows, loading, noBorderLastRow, pagination, selectable, tableMin }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const toggleAll = () => {
    setSelected(selected.length === rows.length ? [] : rows);
  };

  const toggleSelected = item => {
    setSelected(isSelected(item) ? selected.filter(f => f.id !== item.id) : [...selected, item]);
  };

  const isSelected = item => selected.map(m => m.id).includes(item.id);

  React.useEffect(() => {
    if (typeof selectable === 'function') {
      selectable(selected);
    }
  }, [selected, selectable]);

  return (
    <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
      <StyledTable sx={{ minWidth: tableMin ? tableMin : 300 }} aria-label="simple table">
        <TableHead>
          <FlexTableRow>
            {columns?.map((item, key) => (
              <StyledTableCell
                key={key}
                align={item.align ? item.align : key === 0 ? "left" : "left"}
                noWrap={item.noWrap}
                width={item.width}
                flexGrow={item.width === '40px' ? 0 : 1}
              >
                <TitleContainer>
                  {item.title}
                  {item.icon ? <Icon icon={'chevron-down'} nomargin /> : null}
                </TitleContainer>
              </StyledTableCell>
            ))}
          </FlexTableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, rowIndex) => (
            <FlexTableRow key={row.id}>
              {columns?.map((item, key) => (
                <StyledTableCell
                  key={key}
                  align={item.align ? item.align : "left"}
                  noWrap={item.noWrap}
                  noBorder={noBorderLastRow && rowIndex === rows.length - 1}
                  width={item.width}
                  flexGrow={item.width === '40px' ? 0 : 1}
                >
                  <Tooltip title={row[item.ref]} arrow>
                    <span>{item['renderCell'] ? item.renderCell({ row }) : row[item.ref]}</span>
                  </Tooltip>
                </StyledTableCell>
              ))}
            </FlexTableRow>
          ))}
        </TableBody>
      </StyledTable>
      {!loading ? (
        <>
          {rows?.length ? null : <EmptyMessage>Nenhum registro encontrado</EmptyMessage>}
        </>
      ) : (
        <LoadCenter>
          <Load />
        </LoadCenter>
      )}

      {!pagination ? null : (
        <>
          <CustomTablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </>
      )}
    </TableContainer>
  );
}