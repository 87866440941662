import { GET, POST, PUT, DELETE } from './api'

const table = "company-orders"

export const Create = async (params) => {
    return await POST(`/${ table }`, params, true);
}

export const Read = async () => {
    return await GET(`/${ table }`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/${ table }/${id}?populate[0]=antecipations.employee&populate[1]=bank_slip&populate[2]=company`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/${ table }/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/${ table }/${ id }`, true);
} 
 
export const ReadEmployeeCompanyByCPF = async (cpf) => {
    return await GET(`/${ table }?filters[cpf]=${cpf}&populate=*`, true);
}

 